import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 42,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();


  return (
    <svg className={classes.svg} viewBox="0 0 530 529" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_1507_83)">
        <circle cx="265" cy="259" r="243.294" stroke="#434C27" strokeWidth="16.5882" />
      </g>
      <g clipPath="url(#clip0_1507_83)">
        <circle cx="265.346" cy="258.654" r="215.993" fill="#F5F6F4" />
        <g clipPath="url(#clip1_1507_83)">
          <path d="M316.645 135.932C290.073 135.932 266.547 169.326 251.161 194.444L250.406 195.677H245.241V135.279C220.956 144.888 196.636 151.447 167.217 156.34V164.392C181.332 166.471 190.372 168.302 195.58 174.458C200.754 180.572 201.627 189.931 201.627 208.598V323.174C201.627 341.203 200.446 352.227 194.212 358.837C188.557 364.833 179.779 366.819 162.68 368.614V378.446H291.752V368.668C270.142 366.922 259.238 364.98 252.856 358.941C245.754 352.221 245.241 340.946 245.241 323.174V223.971L245.435 223.5C257.419 194.289 274.997 176.143 291.31 176.143C302.313 176.143 309.293 180.55 317.631 188.162C320.646 190.842 324.816 191.202 329.998 189.206C341.163 183.938 348.095 173.41 348.095 161.709C348.095 149.261 335.458 135.932 316.645 135.932Z" fill="#2E2E2E" />
          <path d="M344.252 329.79C330.305 329.79 320.186 340.182 320.186 353.582C320.186 368.897 330.305 378.195 343.705 378.195H344.252C358.199 378.195 368.044 368.897 368.044 353.582C368.044 340.182 358.199 329.79 344.252 329.79Z" fill="#434C27" />
        </g>
      </g>
      <defs>
        <filter id="filter0_dd_1507_83" x="0.970444" y="0.499978" width="528.059" height="528.059" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2.76471" />
          <feGaussianBlur stdDeviation="1.38235" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1507_83" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="4.14706" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_1507_83" />
          <feOffset dy="5.52941" />
          <feGaussianBlur stdDeviation="4.14706" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1507_83" result="effect2_dropShadow_1507_83" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1507_83" result="shape" />
        </filter>
        <clipPath id="clip0_1507_83">
          <rect width="431.985" height="431.985" fill="white" transform="translate(49.353 42.6617)" />
        </clipPath>
        <clipPath id="clip1_1507_83">
          <rect width="247.441" height="247.441" fill="white" transform="translate(141.279 135.279)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
