import { Content, Header, InfoCard, Page } from '@backstage/core-components';
import { HomePageStarredEntities, HomePageToolkit } from '@backstage/plugin-home';

import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, useTheme } from '@material-ui/core';
import React from 'react';
import { HomeWelcomeBanner } from './WelcomeBanner/WelcomeBanner';
import { Search } from '../header/search';
import { BackstageTheme } from '@backstage/theme';
import { GitHub } from '@material-ui/icons';




export const HomePage = () => {

    
    const theme: BackstageTheme = useTheme();
    /* We will shortly compose a pretty homepage here. */
    return <SearchContextProvider>
        <Page themeId="home">
            <Header title={<HomeWelcomeBanner />}></Header>
            <Content>

                <Grid container justifyContent="center" spacing={6}>
                    <Grid container item xs={12} justifyContent='center'>
                        <div style={{ height: '60px',width:'800px' }}>
                            <Search backgroundColor={theme.palette.primary.main} placeholder="Search data catalog"></Search>
                        </div>

                    </Grid>
                   
                    <Grid container item xs={12}>
                        <Grid item xs={12} md={6}>
                            <HomePageStarredEntities />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <HomePageToolkit
                                tools={Array(8).fill({
                                    url: '#',
                                    label: 'link',
                                    icon: <GitHub />,
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InfoCard title="Composable Section">
                                {/* placeholder for content */}
                                <div style={{ height: 370 }} />
                            </InfoCard>
                        </Grid>

                    </Grid>
                </Grid>
            </Content>
        </Page>
    </SearchContextProvider>;
};

