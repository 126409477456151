
import {
    AnyApiFactory,
    configApiRef,
    createApiFactory,
    ApiRef, OpenIdConnectApi, ProfileInfoApi, BackstageIdentityApi, SessionApi, createApiRef,
    discoveryApiRef, oauthRequestApiRef
} from '@backstage/core-plugin-api';
import { OAuth2 } from '@backstage/core-app-api';

export const oidcAuthApiRef: ApiRef<
    OpenIdConnectApi & // The OICD API that will handle authentication
    ProfileInfoApi & // Profile API for requesting user profile info from the auth provider in question
    BackstageIdentityApi & // Backstage identity API to handle and associate the user profile with backstage identity.
    SessionApi // Session API, to handle the session the user will have while logged in.
> = createApiRef({
    id: 'auth.example.oidc', // Can be anything as long as it doesn't conflict with other Api ref IDs
});

export const ssoApi: AnyApiFactory = createApiFactory({
    api: oidcAuthApiRef,
    deps: {
        discoveryApi: discoveryApiRef,
        oauthRequestApi: oauthRequestApiRef,
        configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
        OAuth2.create({
            discoveryApi,
            oauthRequestApi,
            provider: {
                id: 'sso-auth-provider',
                title: 'Example Auth Provider',
                icon: () => null,
            },
            environment: configApi.getOptionalString('auth.environment'),
            defaultScopes: ['openid','offline_access','profile','email'],
            popupOptions: {
                size: {
                    fullscreen: true,
                    // or specify popup width and height
                    //width: 1000,
                    //height: 1000,
                },
            },
        }),
});

