import React from "react";
import styles from './WelcomeBanner.module.css';
import { Avatar } from "@backstage/core-components";
import { BackstageTheme } from "@backstage/theme";
import { useTheme } from "@material-ui/core";
import { useUserProfile } from "@backstage/plugin-user-settings";

export const HomeWelcomeBanner = () => {
    const theme: BackstageTheme = useTheme();
    const user = useUserProfile();
    return <>
        <div className={styles.banner}>
            <div>
            <Avatar
                displayName="Jenny Doe"
                customStyles={{border:`1px solid ${theme.palette.background.default}`,height:'100px',width:'100px'}}
                // Avatar of the backstage GitHub org
                picture={user.profile.picture||"https://i.pravatar.cc/300"}
            />
            </div>
            <div className={styles.content}>
            <h2>Hello, {user.displayName}!</h2>
            <p>Welcome to the Rare Developer Portal </p>
           
            </div>
            
        </div>
    </>
}