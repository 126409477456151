import { createTheme, darkTheme, genPageTheme } from '@backstage/theme';
import { shapes } from './util';
const vivintDarkTheme = createTheme({
    fontFamily: 'vivintCircular',
    palette: {
        ...darkTheme.palette,
        primary: {
            main: '#ffffff',

        },
        text: {
            primary: '#ffffff',
        },
        link: '#ffffff',
        bursts: {
            ...darkTheme.palette.bursts,
            // fontColor:'#000000'
            fontColor: '#ffffff'
        },
        background: {
            default: '#1f1e1d',
            paper: '#272625',

        },
        divider: '#444548',
        navigation: {
            background: '#3c3b3a',
            indicator: '#9baba9',
            color: '#ffffff',
            selectedColor: '#9baba9'
        }
    },
    defaultPageTheme: 'home',
    
    pageTheme: {
        home: genPageTheme({ colors: ['#3a3e2a', '#3a3e2a'], shape: shapes.none }),
        documentation: genPageTheme({ colors: ['#9E6F74', '#9E6F74'], shape: shapes.none }),
        visualization: genPageTheme({ colors: ['#007891', '#007891'], shape: shapes.none }),
        service: genPageTheme({ colors: ['#007891', '#007891'], shape: shapes.none }),

    },
});

export {
    vivintDarkTheme
};