
import { SearchBar } from '@backstage/plugin-search-react';

import React, {  useCallback, useState } from 'react';
import style from './search.module.css';

import { useNavigate } from 'react-router-dom';

import qs from 'qs';

const useNavigateToQuery = () => {
    
    const navigate = useNavigate();
    return useCallback(
      ({ query }: { query: string }): void => {
        const queryString = qs.stringify({ query }, { addQueryPrefix: true });
  
        navigate(`/search${queryString}`);
      },
      [navigate],
    );
  };
export let Search = (props:any)=>{
    let {
        placeholder= 'search'
    } = props;
    let {
        backgroundColor = '#ffffff'
    } = props;
    const [query, setQuery] = useState('');
    const handleSearch = useNavigateToQuery();

    const handleSubmit = () => {
      handleSearch({ query });
    };
    const handleChange = useCallback(
        (value:any) => {
          setQuery(value);
        },
        [setQuery],
      );
    return (
        <div className={style.search} style={{backgroundColor:backgroundColor+'1A'}}>
            <SearchBar className={style.search_bar} onSubmit={handleSubmit} onChange={handleChange} placeholder={placeholder}></SearchBar>
        </div>
     
    )
}